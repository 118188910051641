#search{
    width:100% ;
    padding: 10px 30px;
}
.img-search{
    width: 20px;
    margin-top: 10px;
    margin-left: 10px;
    
}
.img-search>img{
    width: 20px;
    
}

@media(min-width:1200px){
    #search{
        padding-left: 60px;
        font-size: 18px;
        font-weight: 400;
        line-height: 133%; /* 21.28px */
    }
    .img-search{
        width: 23px;
        margin-top: 15px;
        margin-left: 10px;
    }

}
@media(min-width:1920px){
    #search{
        padding-left: 60px;
        font-size: 18px;
        font-weight: 400;
        line-height: 133%; /* 21.28px */
    }
    .img-search{
        width: auto;
        margin-top: 25px;
        margin-left: 20px;
    }
}
.subscribe-title,.success-subscribe-title{
    font-family: var(--fsecondary);
}
.appearClass{
    animation: appear .5s ease-out;
}
.disappearClass{
   animation: disappear .5s forwards ease-out;
}
.btn-x{
    background-color: transparent;
    border: none;
    width: 43px;
    height: 43px;
    margin-top: 32px;
    margin-right: 16px;

}
#subscribe-input{
    color: #FFF;
}
.form-floating>.form-control:focus~label{
    color: #FFF;
}
.form-floating>.form-control:focus~label::after{
    background-color: transparent;
}
.modal-style{
    width: 100vw;
    height: 100vh;
    background:rgba(7, 7, 7, 0.50);
    backdrop-filter: blur(25px); 
    -webkit-backdrop-filter:blur(30px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
}
.subscribe-title{
    font-size: 32px;
    font-weight: 400;
    padding-top: 173px;
    padding-left: 16px;
}
.text-subscribe{
    font-size: 16px;
}
.button-form{
    padding: 16px 48px;
    width: 343px;
    height: fit-content;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: #FFFFFF;
    font-size: 18px;
    font-weight: 400;
    line-height: 133%; /* 23.94px */
    letter-spacing: 7.2px;
    margin-left: 16px;
}

.form-subscribe{
    height: 32vh;
}
.full-height{
    height: calc(100vh - 107px);
}
@media (min-width:1200px) {
  
    .subsForm,.subsForm:focus{
        background-color: transparent;
        color: white;
        width: 493px!important;
    }
    .subscribe-title,.success-subscribe-title{
        font-size: 60px;
        padding: 0;
    }
  
    .text-ok{
        width: 100%;
    }
    .section-send{
        height: 70vh;
    }
    .text-subscribe{
        font-size: 24px;
        font-weight: 400;
        line-height: 34px;
        width: 723px;
    }
    .button-form{
        width: 254.095px;
        font-size: 18px;
        font-weight: 400;
        line-height: 133%; /* 23.94px */
        letter-spacing: 7.2px;
    }

        /*-------- modal -------*/
    .modal-style{
        width: 100vw;
        height: 100vh;
        background:rgba(7, 7, 7, 0.50);
        backdrop-filter: blur(25px); 
        -webkit-backdrop-filter:blur(30px);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 5;
    }
    .appearClass{
        animation: appear .5s ease-out;
    }
    .disappearClass{
        animation: disappear .5s forwards ease-out;
    }
    
    .modal-body{
        gap: 97px;
    }
    
    .btn-x{
        width: 43px;
        height: 43px;
        margin-top: 64px;
        margin-right: 64px;
  
    }
    .modal-dialog {
        max-width: 100vw; 
        height: 100vh;
    
    }
    .section-img-modal{
        gap: 20px;
    }
    .image-modal{
        width: 400px;
        padding: 0;
    }
    .linkedin-modal{
        width: fit-content;
        font-size: 18px;
        line-height: 223%;
        letter-spacing: 8.82px;
    }
    .title-modal{
        color: #FFF;
        font-size: 50px;
        font-family: var( --fsecondary);
        line-height: 133%; 
    }
    .text-modal{
        color: #FFF;
        font-size: 16px;
        font-family:  var( --fprimary);
        line-height: 153%; 
    }
    .w-723{
        width: 723px;
        height: fit-content;
    }
    .form-subscribe{
        height: fit-content;
    }
    #subscribe{
        width: 100%;
        height: 100%;
    }
}
@media(min-width:1500px){
    .pb-diference{
        padding-bottom: 107px;
    }
}

@keyframes appear {
    from{
        transform: translateY(100%);
    }
    to{
        transform: translateY(0%);
    }
}

@keyframes disappear {
    from{
        transform: translateY(0%);
    }
    to{
        transform: translateY(100%);
    }
}
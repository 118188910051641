.icon-episodes{
    width: 47px;
    height: 47px;
}
.streaming-icon-section-mb{
    border-top: 0.5px solid white;
    display: flex;
    width: 100%;
    gap: 32px;
    padding: 16px;
}
.streaming-icon-section{
    display: none;
}
.title-card{
    font-size: 18px;
    font-weight: 600;
}
.date-episode{
    font-size: 12px;
    font-weight: 400;
    line-height: 159%; 
}
.episode-number{
    font-size: 12px;
    font-weight: 400;
    line-height: 159%; 
    letter-spacing: 6px;
}
.episode-info-page{
    padding: 16px;
    flex-direction: row;
}
.padding-card{
    padding: 16px;
    gap: 16px;
}
.episode{
    margin-top: 8px;
    margin-left: 16px;
    margin-right: 16px;
}
.img-untangling{
   width: 135px;
}
@media(min-width:768px){
    .container-rep{
        height: fit-content;
        width: fit-content;
    }
}

@media(min-width:1200px){
    .container-rep{
        height: fit-content;
        width: fit-content;
    }
    .streaming-icon-section-mb{
        display: none;
    }
    .episode{
        width: 336px;
        height:240px;
        margin: 0; 
        margin-top: 32px;
    }
    .padding-card{
        padding: 16px;
        gap:20px;
    }
    .episode-info{
        gap: 20px;
        padding: auto;
    }

 
    .episode-number{
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 4px;
    }
    .streaming-icon-section{
        gap: 24px;
        display: flex;
    }

    .icon-episodes{
        width: 37px;
        height: 37px;
    }
    .title-card{
        font-size: 14px;

    }
}
@media(min-width:1500px){
    .episode{
        width: 480px;
        height:367px;
    }
    .padding-card{
        padding: 28px;
    }
    .container-rep{
        height: 211px;
        width: 100%;
    }
    .img-untangling{
        width: 212px;
     }
     .episode-info-page{
        gap: 35px;
     }
     .title-card{
        font-size: 19px;

    }
    .date-episode{
        font-size: 14px;
    }
    .episode-number{
        font-size: 16px;
    }
}
@media(min-width:1920px){  
    .container-rep{
        height: fit-content;
    } 
    .date-episode{
        font-size: 16px;
        font-weight: 300;
    }
    .episode{
        width: 550px;
        height:415px ;
        margin-top: 32px;
    }   
     .title-card{
        font-size: 24px;
        font-weight: 500;

    }
    .episode-info{
        gap: 40px;
        padding: auto;
    }

    .img-untangling{
        width: 243.78px;
    }
    .episode-number{
        font-size: 18px;
        letter-spacing: 9px;
    }
   .icon-episodes{
        width: 48px;
        height: 48px;
    }
    .padding-card{
        padding: 32px;
    }
} 
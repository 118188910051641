.btn-play{
   background-color: var(--bo, rgba(7, 7, 7, 0.50));
}
.container-rep:hover>.btn-play{
   display: block!important;
}
.card-section:hover>.gap-40>.container-rep>.btn-play{
   display: block!important;
}
.card-section{
   padding: 16px;
   flex-direction: column;
   cursor: pointer;
}
.img-untangling-home{
   width: 135px;
}
h3{
   font-size: 18px;
}
.arrow-down{
   display: none;
}
.episode-social{
   flex-direction: column-reverse;
}
.gap-40{
   gap: 16px;
   padding-bottom: 16px;
}
.card-dropdown{
   border-radius: 12px;
   box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.80);
}
.description-ep-home{
   display: none;
}
@media(min-width:768px){
   .img-untangling-home{
      width: 212px;
   }
   .episode-social{
      flex-direction: column;
   }
}
@media (min-width:1200px) {
   .img-untangling-home{
      width: 203px;
   }
   .container-rep,.btn-play{
      width: 136px!important;
   }
   .btn-play{
      width: 136px!important;
   }
   .width-section{
      width: 99%;
   }
   .title-desc-home{
      font-size: 33px;
      font-weight: 600;
   }
   .desc-ep-home{
      font-size: 18px;
      font-weight: 400;
      padding-top: 8px;
   }
   .description-ep-home{
      padding: 32px 0px;
      margin-left: 38px;
      margin-right: 38px;
      width: 95%;
      display: block;
   }
   .card-dropdown{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%!important;
   }
   .card-section{
      padding: 38px;
      flex-direction: row;
      height: 279px!important;
      width: 100%;
   }
   .arrow-down{
      display: block;
   }
   .open{
      transition: transform 1s;
    transform: rotate(180deg);
   }
   .gap-40{
      gap: 40px;
      padding: 0%
   }
   .episode-number{
      font-size: 18px;
      font-weight: 400;
      line-height: 159%; /* 28.62px */
      letter-spacing: 9px;
   }

   .title-desc-hom{
      font-size: 33px;
      font-weight: 600;
      line-height: 159%;
   }
   .desc-ep-home{
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
   }
}

@media (min-width:1500px) {
   .container-rep,.btn-play{
      width: 239px!important;
   }
   .btn-play{
      width: 239px!important;
   }
}
.resume-article{
    width: 90%;
    padding: 24px;
}
.title-article{
    font-size: 24px;
    font-weight: 600;
}
@media(min-width:1200px){
    .resume-article{
        width: 1475px;
        margin-top: 32px;
        align-items: center;
        justify-content: space-between;
        flex-direction: row!important;
    }
    .info-article{
        width: 721px;
    }
    .title-article{
        font-size: 33px;
        line-height: 159%;
    }
    .article-description{
        font-size: 18px;
        font-weight: 400;
        line-height: 29px; 
        padding-top: 16px;
    }
    .date-time-article{
        font-size: 16px;
        line-height: 159%;
    }
}
.team-image{
    padding-top: 32px;
    padding-bottom: 32px;
    width: 100%;
}
.link-host{
    text-align: left;
    font-size: 28px;
    font-weight: 400;
    line-height: 159%; /* 44.52px */
    letter-spacing: 5.6px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #FFF;
}
.host-list{
    padding-top: 24px;
    flex-direction: column;
    gap: 16px;
}
.footer-about{
    margin-left:16px ;
    margin-right: 16px;
    margin-top: 64px;
    margin-bottom: 64px;
}
.title-footer{
    padding-top: 32px;
    padding-bottom: 32px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 159%; /* 28.62px */
    letter-spacing: 3.6px;
}
.margin-mb{
    margin-left:16px ;
    margin-right: 16px;
}
@media(min-width:768px){
    .about-section-info{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 26px;
        padding-top: 64px;
    }
    .about-section-text{
        width: 50%;
    }
    .team-image{
        padding-top: 0px;
        width: 50%;
    }
    .host-list{
        flex-direction: row;
    }
    .link-host{
        font-size: 20px;
    }
}
@media(min-width:1200px){
    margin-mb{
        margin: 0;
    }
    .about-section-info{
        gap:43px
    }
    .host-list{
        gap: 32px;
        padding-top: 32px;
    }
    .link-host{
        font-size: 28px;
    }
    .about-section-text>p{
        width: 80%;
        font-size: 16px;
    }
}
@media(min-width:1720px){
    .about-section-text>p{
        width: 85%;
        font-size: 24px;
    }
}
@media(min-width:1920px){
    .about-section-text>p{
        width: 75%;
        
    }
    .about-section-info{
        justify-content: flex-start;
    }
    .about-section-info{
        gap:64px
    }

}

.title-episode{
    text-align: start;
    font-weight: 400;
    font-size: 18px;
    line-height: 159%; /* 28.62px */
    letter-spacing: 3.6px;
   
}
.container-title-ep{
    padding: 50px 16px;
    flex-direction: column;
}  

@media(min-width:1200px){
    .container-title-ep{
        padding-top: 50px;
        flex-direction: row;
        justify-content: center;
        gap: 220px;
    }    
    .title-episode{
        text-align: center;
        font-size: 20px;
        letter-spacing: 5.6px;
        align-self: flex-end;
    }

    .padding-episodes{
        padding-left: 72px;
        padding-right: 72px;
    
    }
    form>img{
        top: 24px;
        left: 24px;
    }   
    #search{
        width: 693px;
        height: 51px;
    }

    .section-episodes-page{
        gap: 24px;
    }
}

@media(min-width:1920px){
    .container-title-ep{
        gap: 37%;
    }  

    .section-episodes-page{
        gap: 24px;
    }
    .title-episode{
        font-size: 28px;
    }
    #search{
        width: 693px;
        height: 72px;
    }
}
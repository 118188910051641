.hostName{
    font-family: "Tan-Nimbus";
    font-size: 32px;
}
.hostText{
    padding:16px 0px;
    font-size: 18px;
}
.host{
    margin-top:64px;
}

.margin-lateral{
    margin-left: 16px;
    margin-right: 16px;
}
.image-host{
    width: 100%;
    
}
.host-area{
    margin-bottom: 32px;
}
@media(min-width:768px){
    .image-host{
        width: 372px;
        height: fit-content;
    }
    .host{
        margin-top: 67px;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .host-area{
        width:50% ;
        padding-right: 64px;
        padding-left: 64px;
        margin-bottom:0;
    }
    .hostText{
        font-size: 16px;
    }
    .host-area>a{
        width: 100%;
    }
    .hostName{
        font-size: 39px;
    }

}
@media(min-width:1200px){
    .host{
        margin-top: 168px;
        padding-right: 123px;
        padding-left: 123px;
    }
    .host-area{
        padding-right:64px ;
    }
    .reverse-location{
        flex-direction: row-reverse;
    }
    .hostName{
        font-size: 46px;
    }
    .hostText{
        font-size: 16px;
    }
}
@media(min-width:1720px){
    .hostText{
        font-size: 24px;
    }
    .image-host{
        width: 496px;
    }
}
@media(min-width:1920px){
    .image-host{
        width: 596px;
    }
    .host{
        margin-top: 168px;
    }
    .hostName{
        font-size: 60px;
    }

}

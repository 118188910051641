
.img-bg{
    display: none;
}

@media(min-width:780px) {
    .margin-home{   
        margin-right:117px!important;
        margin-left: 117px!important;
        margin-top: 187px;

    }
    .img-bg{
        display: block;
        width: 444px;
    }
    .img-bg-mobile{
        display: none;
    }
    
}

@media(min-width:1200px){
    .img-bg{
        width: 380px;
    }
    .margin-home{   
        margin-top: 100px;

    }
}
@media(min-width:1500px){
    .img-bg{
        width: 555px;
    }
    .hero-desc-and-title{
        width: 790px;
    }
}
@media(min-width:1920px){
    .img-bg{
        width: 710px;
    }
    .margin-home{   
        margin-right:186px!important;
        margin-left: 186px!important;
        margin-top: 187px;
    }
}
.nav-item:hover,.btn-nav:hover{
    border-bottom: 2px solid var(--W1, #FFF);
}
.nav-item{
    transition: border-bottom 1s;
}
.border-bottom-item{
    border-bottom: 2px solid var(--W1, #FFF);
}
.button-subscribe:hover{
    background-color: #FFFF!important;
    color: rgba(18, 18, 18,0.9) !important;
}
.menu{
    flex-direction: column;
    position: absolute;
    top: 69px;
    left: 0;
    height: 99vh;
    width: 100vw;
    background:rgba(7, 7, 7, 0.50);
    backdrop-filter: blur(25px); 
    z-index: 7!important;
    padding: 2rem;
    justify-content: space-evenly;
}
.navegation{
    padding: 1rem;
}
ul{
    list-style-type: none;
    margin: 0;
}

.img-logo{
    width: 60px;
}
.menu-section{
    width: fit-content;
    
} 
.btn-nav{
    color: #ffff;
    font-weight: 400;
    padding-left: .5rem ;
}
.hideMenu{
    display: none;
}
@media screen and (min-width:780px) {
    .menuHamb{
        display: none;
    }
    .hideMenu{
        display: flex;
    }
    .menu{
        position: static;
        flex-direction: row;    
        width: fit-content;
        height: fit-content;
        background-color: transparent;
        backdrop-filter: none;
        padding: 0;
    }
    .nav-item{
        padding: 10px;
        font-size: 12px;
        text-align: center;
        font-family: Roboto;
        letter-spacing: 5.44px;
        font-family:  var(--fprimary);
        background:none ;
    }
}

@media screen and (min-width:1200px) {
    .navegation{
        margin-left: 16px;
        margin-right: 16px;
        padding: 0;
    
    }
    .img-logo{
        width: 74px;
    }
 
    .nav-item{
        padding: 32px 24px;
        font-size: 16px;
    
    }

}
#episodes{
    padding-top: 32px;
    margin-left: 16px;
    margin-right: 16px;
    width: fit-content;
}
.row-episode{
    flex-direction: column-reverse;
}

.title-ep{
    font-size: 18px;
    font-weight: 400;
    line-height: 159%; /* 28.62px */
    letter-spacing: 3.6px;
    padding-top: 8px;
    margin-bottom:24px
}

.input-search{
    padding: 5px 34px;
    
 }
 #searchInput{
    color: #FEFCFD;
 }
.top-glass{
    top: 5px;
    left: 24px;
    
}
.top-glass>img{
    width: 20px;
}
.space-button-load{
    margin-top: 24px;
    margin-bottom: 15px;        
}
.button-load-ep{
    padding: 16px 48px;
    border-radius: 100px;
    background: #FEFCFD;
    font-size: 16px;
    font-weight: 400;
    line-height: 133%; /* 21.28px */
    letter-spacing: 9.6px;
    width: fit-content;
}
@media screen and (min-width:768px){
    #episodes{
        padding-left: 16px;
        padding-right: 16px;
        margin: 0;
        width: 100%;
    }

}
@media (min-width:1200px){
    .top-glass{
        top: 20px;
    }
  .title-ep{
        font-size: 28px;
        letter-spacing: 5.6px;
        margin-bottom: 0px;
        padding-top: 0px;
    }
    .row-episode{
        margin:32px 0px;
        flex-direction: row;
    }
    .input-search{
       padding: 24px 55px;
    }

    .space-button-load{
        margin-top: 54px;
        margin-bottom: 79px;        
    }

    .top-glass>img{
        width: 31px;
    }
}

@media(min-width:1500px){
    .section-list-episodes{
        justify-content: center;
    }
}



.name-thanks{
    line-height: 159%; /* 28.62px */
    letter-spacing: 3.6px;
    text-transform: uppercase;
}
.thanks{
    font-size: 18px;
    font-weight: 400;
    text-align: center;
}
.text-thanks{
    line-height: 34px;
    padding-bottom: 24px;
}
.media-section-thanks{
    gap: 24px;
    padding-bottom: 32px;
}
.media-icon-thanks{
    width: 39px;
}
.header-area{
    flex-direction: column;
}

.logo-hero{
    width: 73px;
    padding-top: 40px;
    margin-bottom: 32px;
}
.header{
    margin-top: 40px;
}
.padding-mobile{
    padding-left: 16px;
    padding-right: 16px;
}
h1{
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 159%;
    font-family: var(--fsecondary);
    margin-bottom: 32px;

}
.hero-subtitle{
    font-family: var(--fsecondary);
    font-size: 30px;
}
.vh-100{
    height: fit-content!important;
}
.section-disc{
    gap: 8px;
    padding-bottom: 32px;
}
.desc-header{
    margin-top: 32px;
    margin-bottom: 32px;
    font-size: 18px;
    font-weight: 400;
}

.button-header{
    margin-left: 2px;
    padding: 16px 36px;
    margin-top: 48px;
    background: #FEFCFD;
    border-radius: 100px;
    border-width: 0px;
    color: #000000;
    font-weight: 400;
    line-height: 133%; 
    width: fit-content;
    font-size: 16px;
    letter-spacing: 9.6px;
}
.animation-disc{
    animation:playRecord 1s infinite cubic-bezier(.5,.51,.7,.69);
    width: 36px;
}
@media (min-width:780px) {
    .padding-mobile{
        padding: 0px;
    }
    .header{
        justify-content: center;
        margin: 0;
    }
    h1{
        font-size: 40px;
        margin-bottom: 0;
    }
    .hero-subtitle{
        font-size: 35px;
    }
    .button-header{
        font-size: 18px;
        letter-spacing: 10.8px;
        padding: 16px 48px;
    }
    .logo-hero{
        width: 203px;
        padding-top: 63px;
    }

}

@media(min-width:1200px){
    .header-area{
        justify-content:space-around ;
        flex-direction: row;
        flex-wrap: nowrap;
    }
    .logo-container{
        height: 50%;
    }
    .desc-header{
        font-size: 18px;
       
    }

    .logo-hero{
        width: 130px;
    }
    .button-header:hover, .button-header:active{
        background-color: #7D8089;
        color: #FEFCFD;
    }
    .scroll-down{
        font-size: 17px;
        font-weight: 400;
        line-height: 159%; /* 27.03px */
        letter-spacing: 11.9px;
    }
    .section-disc{
        gap: 26px;
    }
    .animation-disc{
        width:56px ;
    }
    .pe-xl-6{
        padding-right: 80px;
    }

}

@media(min-width:1500px){
    .logo-hero{
        width: 203px;
    }    
    .section-disc{
        padding-bottom: 121px;
    }
}
@media(min-width:1920px){
    h1{
        font-size: 43px;
    }
    .hero-subtitle{
        font-size: 38px;
    }

    .pe-xl-6{
        padding-right: 0;
    }

}


@keyframes playRecord {
    0%{
        transform: rotate(0deg);
    }
    50%{
        transform: rotate(180deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
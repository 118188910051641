@font-face {
  font-family: 'Tan-Nimbus';
  src: url('./fonts/TAN-NIMBUS/TAN-NIMBUS.otf');
}


:root{
  --fsecondary:'Tan-Nimbus';
  --fprimary:'Roboto', sans-serif;
}
*{
  box-sizing: border-box!important;
  margin: 0px;
  padding: 0;
  font-weight: 100;
  font-family: var(--fprimary);
}
body{
  background-color: #121212;
  color: #FFFFFF;
}
p{
  margin: 0;
}
.gap-disc{
  gap:32px
}
.my-32{
  margin-top: 32px;
  margin-bottom: 32px;
}
.img-bg,.img-bg-mobile{
    z-index: -1;
}
.img-bg{
  display: none;
}
.img-secondary{
  display: none;
}

@media(min-width:768px){
  .img-secondary{
    display: block;
    z-index: -2;
  } 

  .img-bg-mobile{
      display: none;
  }  
}
@media(min-width:1200px){
  .img-bg{
    width: 710px;
    display: block;
}

  .gap-disc{
    gap:100px
  }
}


@media(min-width:1500px){
  .gap-disc{
    gap: 205px;
}
}


.appearClass{
    animation: appear .5s ease-out;
}
.disappearClass{
   animation: disappear .5s forwards ease-out;
}


.episode-info {
    flex-direction: column;
    padding: 16px;

}
.gap-48{
    gap: 48px;
}
.episode-number{
    font-size: 14px;
    margin-bottom: 32px;
}
.title-ep-rep{
    font-size: 24px;
}
.date-time-ep{
    font-size: 16px;
    padding-bottom: 27px;
}
.desc-ep{
    overflow: scroll;
    height:600px;
}
iframe{
    width: 100%;
}
.btn-x{
    width: 43px;
    height: 43px;
}
.btn-x>img{
    width: 100%;
}
@media(min-width:768px){
    iframe{
        height: 427px;
    }
}
@media (min-width:1200px) {
    .background-img-static{
        background-image: url(./background.png);
    }
    .background-static{
        background-size: cover;
        width: 100vw;
        height: 100%;
        position: fixed;
        top: 0;
        z-index: 8;
   
    }
    .episode-info {
        flex-direction: row;
    }
    .listening{
        padding-top: 65px;
    }
    iframe{
       width: 1017px;
        
    }
    .title-ep-rep{
        margin: 32px 0 16px 0;
        font-size: 33px;
        font-weight: 600;
        line-height: 117%;
    }
    .date-time-ep{
        margin-bottom: 27px;
    }
    .gap-48{
        padding: 0px 120px 0px 120px;
    }
    .section-info-ep{
        width: 590px;
        height: 700px;
        overflow: scroll;
        padding: 0px 32px;
    }
    .desc-ep{
        font-size: 18px;
        font-weight: 400;
        line-height: 29px;
        height: 756px;
        overflow: visible;
    }
    .modal-style{
        width: 100%;
        height: 100%;
        background:rgba(7, 7, 7, 0.50);
        backdrop-filter: blur(25px); 
        -webkit-backdrop-filter:blur(30px);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 8;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .appearClass{
        animation: appear .5s ease-out;
    }
    .disappearClass{
        animation: disappear .5s forwards ease-out;
    }
    
    
    .btn-x{
        background-color: transparent;
        border: none;
        width: 43px;
        height: 43px;
        margin-top: 64px;
        margin-right: 64px;
  
    }

    .w-723{
        width: 723px;
        height: fit-content;
    }
}

@media(min-width:1500px){
    .episode-info{
        padding-top: 100px;
    }
    iframe{
        width: 100%;
        height: 576px;
    }
}
@keyframes appear {
    from{
        transform: translateY(100%);
    }
    to{
        transform: translateY(0%);
    }
}

@keyframes disappear {
    from{
        transform: translateY(0%);
    }
    to{
        transform: translateY(100%);
    }
}
.title-comming{
    font-family:  var(--fsecondary);
    font-size: 32px;
    margin-top: 40vh;
}
@media(min-width:1200px){
    .search-blog{
        width: 1619px;
        margin: auto;
    }
    .title-comming{
        font-size: 45px;
    }
}